import React, { useState, useEffect } from 'react';
import { Tab, Tabs, Typography } from '@material-ui/core';
import { useHistory, Link, useLocation } from 'react-router-dom';

const RequestReviewSettingsTabs = ["b2busers", "customer", "employee", "tcicustomer"];

function RequestTypeWrapper(props) {
    const {
        active,
        title,
        renderTabContent,
        routePath,
        additionalTab
    } = props;
    const [activeTab, setActiveTab] = useState(-1)
    const history = useHistory()
    const location = useLocation();

    let requestTypeTabs = RequestReviewSettingsTabs;
    if(additionalTab && requestTypeTabs.indexOf(additionalTab.name) == -1){
        requestTypeTabs.push(additionalTab.name)
    }
    useEffect(() => {
        // Activating the Tab based on the tab param in the route url
        let activeTabIndex = requestTypeTabs.indexOf(active);

        // activating the first tab when the tab name does not match the list
        if (activeTabIndex < 0) {
            activeTabIndex = 0;
        }
        setActiveTab(activeTabIndex);
    }, [active])

    return (
        <>
            <Typography variant="h6" className='scopeHeader'>
                <Link color="primary" to={() => {
                    // Generating a Random Id and passing it as part of state 
                    // Every click on the Header will generate a unique Id
                    const autoId = Math.round(Math.random() * 1e5);
                    const subTab = RequestReviewSettingsTabs[activeTab];
                    return {
                        pathname: `/admin/${routePath}/` + subTab,
                        state: { fromredirection: autoId }
                    }
                }}
                >
                    {title}
                </Link>
            </Typography>
            <Tabs
                variant="scrollable"
                aria-label="tabs"
                value={activeTab}
                onChange={(_event, newValue) => {
                    setActiveTab(newValue);
                    // redirecting to the selected tab url so that refresh will retain in same page
                    history.push(`/admin/${routePath}/${requestTypeTabs[newValue]}`);
                }}
                sx={{ borderRight: 1, borderColor: 'divider' }}
            >
                <Tab label="B2B Users" />
                <Tab label="Customer" />
                <Tab label="Employee" />
                <Tab label="TCI Customer" />
                {additionalTab && (<Tab label={additionalTab.display} />)}
            </Tabs>

            {activeTab >=0 && renderTabContent({
                activeTab: activeTab,
                fromredirection: location?.state?.fromredirection,
                ...props
            })}
        </>
    )
}
export default React.memo(RequestTypeWrapper);