/**
 * routingvalidator
 * 
 * React component which is rendered as part of the Tracking View
 */

import React, { useState, useContext } from 'react';
import { Box, Button, Grid, Snackbar, TextField, Typography, makeStyles } from '@material-ui/core';
import { LoadingIndicator } from '../reports/reconcilationreport/MuiStyled';
import { getFormatedDate, v2UIRoutePath } from '../../../common/commons';
import { Link } from 'react-router-dom';
import { RoutingValidatorFields } from './constants'
import { AppContext } from '../../../../RoutesWithAuth';
import axios from '../../../common/AxiosConfig';
import { Alert } from '@material-ui/lab';
import DataGrid from '../grid';

// Custom page Level Styling
const useStyles = makeStyles(() => ({
    root: {
        '& .discrepancy-cell': {
            color: "red"
        },
        display: 'flex',
        flexDirection: 'column',
        flex: '1',
        height: '100%',
        '& #file_upload, #select_date': {
            paddingTop: '8px',
            paddingLeft: '10px',
            paddingBottom: '10px',
        },
        '& .MuiButton-label': {
            textTransform: 'capitalize'
        },
        '& input[type="file"]::file-selector-button': {

            height: '35px',
            marginTop: '-9px',
            marginLeft: '-9px',
        }

    },
    formGroupRootCls: {
        '& .ml-1': {
            fontWeight: 'bold',
            width: '100%'
        },
        '& .MuiInputBase-input': {
            paddingTop: '5px',
            paddingBottom: '5px',
            fontSize: '0.8rem',
            paddingLeft: '10px'
        },
        '& .MuiListItem-root': {
            fontSize: '0.8rem',
            padding: '0px'
        }
    },
    'bulkUplodWrapper': {
        width: 'calc(100% - 30px)',
        margin: '10px auto',
        border: '1px solid #dedfdf',
        padding: '50px 30px'
    },
    'noteswrapper': {
        fontStyle: 'italic',
        paddingLeft: '15px',
        marginTop: '5px'
    },
    ListTop: {
        marginTop:'15px',
        display: 'flex',
        justifyContent: "center",
        '& .listitemWrapper': {
            marginRight: '15px'
        }
    },
}));
const RoutingValidator = () => {
    const classes = useStyles();
    // State Used to handle the API process
    const [isInProgress, setIsInProgress] = useState(false);
    const [requestId, setRequestId] = useState();
    const [displayErrorMsg, setDisplayErrorMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");
    const [routingData, setRoutingData] = useState();

    const context = useContext(AppContext)
    const authToken = context.authToken.get



    const fetchData = (dsarRequestId) => {
        setIsInProgress(true);
        axios.get(`tickets/validate/${dsarRequestId}`, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            setIsInProgress(false);
            const responseData = res.data;

            let discrepancies = 0;
            let simulationApps = responseData["Simulation"]?.apps || [];
            const routingAppsMeta = {};
            const routingApps = responseData["Actual Routing"]?.apps?.map((app, index) => {
                let discrepancy = false;
                if (simulationApps.indexOf(app) === -1) {
                    discrepancy = true;
                    discrepancies = discrepancies + 1;
                }
                routingAppsMeta[app] = true;
                return {
                    displayName: app,
                    id: index + 1,
                    discrepancy
                }
            })
            simulationApps = simulationApps?.map((app, index) => {
                let discrepancy = false;
                if (!routingAppsMeta[app]) {
                    discrepancy = true;
                    discrepancies = discrepancies + 1;
                }
                return {
                    displayName: app,
                    id: index + 1,
                    discrepancy
                }
            })
            const routingFormatedData = {
                ...responseData,
                actualRouting: {
                    ...responseData["Actual Routing"],
                    apps: routingApps || [],
                    created_Date_utc: getFormatedDate(responseData["Actual Routing"]["created_date"], true),

                },
                simulation: {
                    ...responseData["Simulation"],
                    apps: simulationApps || [],
                    created_Date_utc: getFormatedDate(responseData["Simulation"]["created_date"], true),

                },
                discrepancies
            }
            setRoutingData(routingFormatedData);

        }).catch(err => {
            setIsInProgress(false);
            if (err?.response?.data?.message) {
                setDisplayErrorMsg(err.response.data.message)
            }
        })
    }
    const handleClear = () => {
        setRoutingData(null);
        setRequestId("")
    }
    /**
     * Triggered when Alert snak bar is getting closed
     */
    const handleClose = () => {
        // Resetting both the success and failure messages
        setDisplayErrorMsg("");
        setSuccessMsg("");

    }

    const handleCheck = () => {
        if (!requestId) {
            setDisplayErrorMsg("Please enter a valid DSAR ID to proceed")
        } else {
            fetchData(requestId)
        }
    }

    return (
        <div className={classes.root}>
            {isInProgress && (<>
                <LoadingIndicator />
            </>)}
            <Snackbar open={!!displayErrorMsg} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    {displayErrorMsg}
                </Alert>
            </Snackbar>
            <Snackbar open={!!successMsg} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    {successMsg}
                </Alert>
            </Snackbar>
            <Typography variant="h6" className={'scopeHeader'}>
                <Link color="primary" to={() => {
                    // Generating a Random Id and passing it as part of state 
                    // Every click on the Header will generate a unique Id
                    const autoId = Math.round(Math.random() * 1e5);

                    return {
                        pathname: v2UIRoutePath + "tracking/routingvalidator",
                        state: { fromredirection: autoId }
                    }
                }}
                >
                    ROUTING LOGIC VALIDATOR
                </Link>
            </Typography>
            <div style={{ marginTop: '5px' }}>
                <Grid container >
                    <Grid item xs={5} >
                        <div className="form-group col">
                            <label for={"request_id"} style={{
                                fontWeight: 'bold',
                                paddingRight: '20px',
                                paddingTop: '6px'
                            }}>{"Request ID"}</label>
                            <TextField
                                size={"small"}
                                onChange={(event) => { setRequestId(event.target.value) }}
                                width='500px'
                                fullWidth={true}
                                value={requestId}
                                id={"request_id"}
                                data-testid="request_id"
                                label=""
                                variant="outlined"
                            />
                        </div>
                    </Grid>
                    <Grid item xs={3} style={{
                        display: "flex",
                        alignItems: "center",
                        paddingTop: "18px"
                    }}>

                        <Button
                            variant="contained"
                            color="primary"
                            style={{ marginRight: "10px", height: "33px" }}
                            onClick={handleCheck}
                            data-testid="check-btn">
                            Check
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ marginRight: "10px", height: "33px" }}
                            onClick={handleClear}>
                            Clear
                        </Button>
                    </Grid>

                </Grid>
                {routingData && (
                    <Box bgcolor={"#f8f9fb"} border={"1px solid #dedfdf"}>
                         <div className={classes.ListTop}>
                            {RoutingValidatorFields.map(({ display, mapping }, index) => {

                                return (
                                    <div key={display} className='listitemWrapper'>
                                        <div>
                                            <b>
                                                {display} : {" "}
                                            </b>
                                            {routingData[mapping]}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <Box textAlign={"center"} margin={"20px 0px"}>
                            There are
                            <Box component={"b"} color={routingData.discrepancies > 0 ? "red" : "green"}>
                                {` ${routingData.discrepancies} `}
                            </Box>
                            routing logic discrepancies
                        </Box>
                       
                        <Grid container justifyContent='space-evenly'>
                            <Grid item xs={4} >
                                <Box textAlign={"center"}>
                                    <b> Workflow :</b> {routingData?.actualRouting?.workflow}
                                </Box>
                                <div >
                                    <DataGrid
                                        autoHeight={true}
                                        enabledarktheme={true}
                                        customHeight={"100%"}
                                        columns={[{
                                            name: "#",
                                            mapping: "id",
                                            disableColumnMenu: true,
                                            width: 55
                                        }, {
                                            name: 'Actual Routing',
                                            mapping: "displayName",
                                            disableColumnMenu: true,

                                        }]}
                                        getCellClassName={(params) => {
                                            // Displaying the Custom column Class for the Element Type 
                                            if (params?.field === "displayName" && params?.row?.discrepancy) {
                                                return 'discrepancy-cell';
                                            }
                                            return "";
                                        }}
                                        rows={routingData?.actualRouting?.apps || []}
                                    />
                                </div>
                                <Box textAlign={"center"} fontWeight={"bold"}>
                                    {routingData?.actualRouting?.created_Date_utc}
                                </Box>
                            </Grid>
                            <Grid item xs={4}  >
                                <Box textAlign={"center"}>
                                    <b> Workflow :</b>{routingData?.simulation?.workflow}
                                </Box>
                                <div>
                                    <DataGrid
                                        autoHeight={true}
                                        customHeight={"100%"}
                                        columns={[{
                                            name: "#",
                                            mapping: "id",
                                            disableColumnMenu: true,
                                            width: 55
                                        }, {
                                            name: 'Simulation',
                                            mapping: "displayName",
                                            disableColumnMenu: true
                                        }]}
                                        getCellClassName={(params) => {
                                            // Displaying the Custom column Class for the Element Type 
                                            if (params?.field === "displayName" && params?.row?.discrepancy) {
                                                return 'discrepancy-cell';
                                            }
                                            return "";
                                        }}
                                        rows={routingData?.simulation?.apps || []}
                                    />
                                </div>
                                <Box textAlign={"center"} fontWeight={"bold"}>
                                    {routingData?.simulation?.created_Date_utc}
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                )}
            </div>
        </div>
    )
}

export default React.memo(RoutingValidator);