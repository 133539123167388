import React from 'react';
import { withNavbar } from '../common/Navbar';
import PageHeader from '../common/PageHeader';


function LinksPage(props) {
    const { header } = props

    return (
        <div className="container">

            <PageHeader header={header} newUIHref={"/admin/catalog/documents"}/>

            <div className="mt-4 mb-5">
                <div className="card mb-2 bg-light">
                    <div className="card-body">
                        {linksMap[header].map(l => (
                            <div key={l.title}>
                                <a href={l.link} target="_blank" rel="noopener noreferrer">{l.title}</a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

        </div>

    );
}

export default withNavbar(LinksPage, 2);


const linksMap = {
    "Documents": [
        {
            title: "Admin Functionality",
            link: "https://mypartners.toyota.com/sites/tmnaCVT/CCPA/Shared%20Documents/CPS_Resources/Admin%20Functionality.pdf",
        },
        {
            title: "CCPA Workflow",
            link: "https://mypartners.toyota.com/:b:/r/sites/tmnaCVT/CCPA/Shared%20Documents/CPS_Resources/CCPA%20Workflow.pdf",
        },
        {
            title: "Operations Manual",
            link: "https://mypartners.toyota.com/sites/tmnaCVT/CCPA/Shared%20Documents/CPS_Resources/Operations%20Manual.pdf",
        },
        {
            title: "Timing Chart",
            link: "https://mypartners.toyota.com/sites/tmnaCVT/CCPA/Shared%20Documents/CPS_Resources/Timing%20Chart.pdf",
        },
        {
            title: "TIPS Escalation Matrix",
            link: "https://mypartners.toyota.com/:b:/r/sites/tmnaCVT/CCPA/Shared%20Documents/CPS_Resources/TIPS%20Escalation%20Matrix.pdf",
        },
        {
            title: "FTP Key Expiry",
            link: "https://mypartners.toyota.com/:b:/r/sites/tmnaCVT/CCPA/Shared Documents/CPS_Resources/FTP Key Expiry.pdf?csf=1&web=1&e=MlpaG9pdf"
        }]
}
