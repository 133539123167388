/**
 * BulkUpload
 * 
 * React component which is rendered as part of the Tracking View
 */

import React, { useState, useContext } from 'react';
import { Button, Grid, Snackbar, TextField, Typography, makeStyles } from '@material-ui/core';
import { LoadingIndicator } from '../reports/reconcilationreport/MuiStyled';
import { v2UIRoutePath } from '../../../common/commons';
import { Link } from 'react-router-dom';
import FormGroup from '../FormGroup';
import moment from 'moment';
import DeleteDialog from '../admin/settings/DeleteDialog';
import { AppContext } from '../../../../RoutesWithAuth';
import axios from '../../../common/AxiosConfig';
import { Alert } from '@material-ui/lab';



// Custom page Level Styling
const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1',
        height: '100%',
        '& #file_upload, #select_date' :{
            paddingTop: '8px',
            paddingLeft: '10px',
            paddingBottom: '10px',
        },
        '& .MuiButton-label':{
            textTransform : 'capitalize'
        },
        '& input[type="file"]::file-selector-button':{
           
            height : '35px',
            marginTop : '-9px',
            marginLeft : '-9px',
        }

    },
    formGroupRootCls: {
        '& .ml-1': {
            fontWeight: 'bold',
            width: '100%'
        },
        '& .MuiInputBase-input': {
            paddingTop: '5px',
            paddingBottom: '5px',
            fontSize: '0.8rem',
            paddingLeft: '10px'
        },
        '& .MuiListItem-root': {
            fontSize: '0.8rem',
            padding: '0px'
        }
    },
    'bulkUplodWrapper' : {
        width: 'calc(100% - 30px)',
        margin: '10px auto',
        border: '1px solid #dedfdf',
        padding: '50px 30px'
    },
    'noteswrapper' : {
        fontStyle : 'italic',
        paddingLeft : '15px',
        marginTop : '5px'
    }
}));
const BulkUpload = () => {
    const classes = useStyles();
    // Formating the date based on the current date
    const yesterday = moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD')
    // State Used to handle the API process
    const [isInProgress, setIsInProgress] = useState(false);
    const [date, setDate] = useState(yesterday);
    const [files, setFiles] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [type, setType] = useState();
    const [displayErrorMsg, setDisplayErrorMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");

    const context = useContext(AppContext)
    const userId = context.userId.get
    const authToken = context.authToken.get

    /**
     * Method is called when ever a file is browsed
     * @param {Event Object} e 
     */
    const handleFileChange = (e) => {
        e.persist() // Persist the state of the event
        const files = e.target.files

        // Initialize the new state of 'files'
        const arrayOfFiles = []

        for (const file of files) {
            arrayOfFiles.push(file)
        }

        // Set the state after arrayOfFiles has been populated
        setFiles(arrayOfFiles)
    }
    /**
     * Method is called when ever we click on the clear button
     */
    const handleClear = () => {
        // reseeting the selected files
        setFiles([])
        // Updatingg the file input field to empty
        const attachmentsInput = document.getElementById(`file_upload`)
        attachmentsInput.value = null;
        // resetting the date to its original value
        setDate(yesterday);
    }
    /**
     * Triggered when user clicks on the bulk upload
     */
    const handleBulkResponseButnAction = () => {
        // Displaying the Dialog for confirmation on upload
        setOpenDialog(true);
    }
    /**
     * Triggered when cancel is clicked on the dialog
     */
    const handleDialogClose = () => {
        // Closing the confirmation dialog
        setOpenDialog(false);
    }
    /**
     * Triggered when user click on submit in the confirmation dialog
     */
    const handleDialogSubmit = () => {
        // Closin the Confirmation dialog
        setOpenDialog(false);
        // Adding the Loader to show progress
        setIsInProgress(true);
        let bulkapiurl = 'response/bulk_upload_by_date';
        if (type !== "sxmrtd") {
            bulkapiurl = "response/bulk_upload";
        }
        const fileNames = files.map(({ name }) => name);
        // API call where we send all the file names which wee are uploading
        axios.post(bulkapiurl, {
            attachment_filenames: fileNames,
            app_id: 11,
            user_id: userId,
            date: date,
        }, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            setIsInProgress(false);
            setSuccessMsg(res.data.message)
            // Upload the attachments using the presigned URLs returned by the API
            const posts = res.data.presignedPosts
            if (!posts.length) {

                return
            }
            posts.forEach((post) => {
                // For the case where there are multiple files, find the proper file from the array
                const filename = post.fields.key.split('/').pop()
                // Populate the form data
                const formData = new FormData()
                for (const key in post.fields) {
                    formData.append(key, post.fields[key])
                }
                for (const file of files) {
                    if (file.name === filename) {
                        formData.append('file', file)
                    }
                }
                // Uploading the attachments into the AWS signed urls
                axios.post(post.url, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                }).then(res => {
                    console.log(res)
                }).catch(err => {
                    console.log(err)
                })
            })

        }).catch(err => {
            console.log(err)
            setIsInProgress(false);
            // Display an error message to the user if the creation did not complete
            setDisplayErrorMsg(err.response.data.message);

        })
    }
    /**
     * Triggered when Alert snak bar is getting closed
     */
    const handleClose = () => {
        // Resetting both the success and failure messages
        setDisplayErrorMsg("");
        setSuccessMsg("");
    }
    return (
        <div className={classes.root}>
            {isInProgress && (<>
                <LoadingIndicator />
            </>)}
            <Snackbar open={!!displayErrorMsg} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    {displayErrorMsg}
                </Alert>
            </Snackbar>
            <Snackbar open={!!successMsg} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    {successMsg}
                </Alert>
            </Snackbar>
            <Typography variant="h6" className={'scopeHeader'}>
                <Link color="primary" to={() => {
                    // Generating a Random Id and passing it as part of state 
                    // Every click on the Header will generate a unique Id
                    const autoId = Math.round(Math.random() * 1e5);

                    return {
                        pathname: v2UIRoutePath + "tracking/requestqueue",
                        state: { fromredirection: autoId }
                    }
                }}
                >
                    BULK UPLOAD
                </Link>
            </Typography>
            <FormGroup
                formWrapperClassName={classes.formGroupCls}
                fields={[{
                    type: 'multiselect',
                    name: 'responsefiletype',
                    label: 'Response File Type',
                    isMulti: false,
                    removeCheckBox: true,
                    customInputCls: classes.customInputCls,
                    options: ["SXM Audio - Remaining Rights", "SXM Audio - Right to Delete"],
                    defaultOption: 'SXM Audio - Remaining Rights',
                    size: 3
                }]}
                disableFormActions={true}
                customFormUpdates={(formData) => {
                    if (formData.responsefiletype === "SXM Audio - Remaining Rights") {
                        setType("sxmaudio")
                    } else {
                        setType("sxmrtd");
                    }
                }}
            />
            <div>
                <div className={classes.bulkUplodWrapper}>
                    <Grid container className={`${classes.formGroupRootCls}`}>
                        <Grid item xs={3} key={"textField_select_date"} style={{paddingLeft : "50px"}}>
                            <div className="form-group col">
                                <label for={"select_date"} className="ml-1">{"Select Date"}</label>
                                <TextField size={"small"}
                                    type={"date"}
                                    onChange={(event) => { setDate(event.target.value) }}
                                    value={date}
                                    // fullWidth
                                    id={"select_date"}
                                    label=""
                                    variant="outlined"
                                    inputProps={{
                                        max : yesterday,
                                        min : "2020-01-01"
                                    }}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={5} key={"textField_file_upload"}>
                            <div className="form-group col">
                                <label for={"file_upload"} className="ml-1">{"Upload File"}</label>
                                <TextField size={"small"}
                                    type={"file"}
                                    onChange={handleFileChange}
                                    // value={files}
                                    fullWidth
                                    id={"file_upload"}
                                    label=""
                                    variant="outlined"
                                    inputProps={{
                                        accept: "image/*",
                                        required: true,
                                        multiple: true
                                    }}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={10} alignContent={"center"} style={{ maxWidth: '500px' ,margin: '30px auto 0px'}}>
                            <Button
                                style={{marginRight : '50px'}}
                                variant="contained"
                                color="primary"
                                onClick={handleClear}>Clear</Button>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={!files.length}
                                onClick={handleBulkResponseButnAction}>Create Bulk Response</Button>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.noteswrapper}>
                    Note :
                    <ul>
                        <li>The response for all ‘Open’ requests for the selected date and downstream will be updated</li>
                        <li>Multiple files can be uploaded as screenshots to the Response</li>
                    </ul>
                </div>
            </div>
            <DeleteDialog
                openDeleteDialog={openDialog}
                handleDeleteDialogClose={handleDialogClose}
                title={"Confirm Bulk Upload"}
                handleDeleteDialogSubmit={handleDialogSubmit}
            >
                <div>
                    {type === "sxmrtd" ? `Mark all Right to Delete requests sent to SXM Audio on ${date} as success?` : `Mark all requests except Right to Delete sent to SXM Audio on ${date} as success?`}
                </div>
            </DeleteDialog>
        </div>
    )
}

export default React.memo(BulkUpload);