export const RoutingValidatorFields = [{
    display: 'Request ID',
    mapping: 'dsar_id'
}, {
    display: "Requestor",
    mapping: 'requestor'
}, {
    display: "Request Type",
    mapping: 'request_type'
}, {
    display: "Request Status",
    mapping: 'status'
}]