import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import Home from './components/routes/home/Home'
import CreateRequest from './components/routes/CreateRequest';
import RequestDetails from './components/routes/requestDetails/RequestDetails';
import MarkRequestCreated from './components/routes/MarkRequestCreated';
import CreateResponse from './components/routes/CreateResponse';
import AuditTrail from './components/routes/AuditTrail';
import ViewJSON from './components/routes/ViewJSON';
import ViewResponse from './components/routes/ViewResponse';
import ViewResponseFiles from './components/routes/ViewResponseFiles';
import UpdateResponse from './components/routes/UpdateResponse';
import GuidDetails from './components/routes/GuidDetails';
import ContactsTab from './components/routes/contacts/Contacts';
import LinksPage from './components/routes/Documents';
import Health from './components/routes/CPSHealth';
import Automation from './components/routes/apps/Apps';
import MasterAttributeKeys from './components/routes/rtkDataDictionary/RtkDataDictionary';
import BulkAssign from './components/routes/BulkAssign';
import Users from './components/routes/users/Users';
import Retention from './components/routes/retention/Retention';
import LitigationHold from './components/routes/litigationHold/LitigationHold'
import ProcessingRequests from './components/routes/processingRequests/ProcessingRequests';
import CtpAggregator from './components/routes/ctpAggregator/CtpAggregator';
import AutoCloseSettings from './components/routes/autocloseSettings/AutoCloseSettings';


const OldUIRouter = () => {
    return (
        <>
            <Route exact path="/" render={(r) => <Home routerProps={r} tab="requests" />} />
            <Route exact path="/page/:page" render={(r) => <Home routerProps={r} tab="requests" />} />
            <Route exact path="/daily" render={(r) => <Home routerProps={r} tab="daily" />} />
            <Route exact path="/bulk" render={(r) => <Home routerProps={r} tab="bulk" />} />
            <Route exact path="/reports" render={(r) => <Home routerProps={r} tab="reports" />} />
            <Route exact path="/reports/:reportType" render={(r) => <Home routerProps={r} tab="reports" />} />
            <Route exact path="/ticket/create" component={CreateRequest} />
            <Route exact path="/ticket/:dsarRequestId" component={RequestDetails} />
            <Route exact path="/ticket/:dsarRequestId/guidDetails" component={GuidDetails} />
            <Route exact path="/ticket/:dsarRequestId/viewRequest" component={ViewJSON} />
            <Route exact path="/ticket/:dsarRequestId/auditTrail" component={AuditTrail} />
            <Route exact path="/ticket/:dsarRequestId/viewResponseJSON" component={ViewJSON} />
            <Route exact path="/ticket/:dsarRequestId/viewResponseFiles" component={ViewResponseFiles} />
            <Route exact path="/ticket/:dsarRequestId/:appId/markCreated" component={MarkRequestCreated} />
            <Route exact path="/ticket/:dsarRequestId/:appId/createResponse" component={CreateResponse} />
            <Route exact path="/ticket/:dsarRequestId/:appId/viewResponse" component={ViewResponse} />
            <Route exact path="/ticket/:dsarRequestId/:appId/updateResponse" component={UpdateResponse} />
            <Route exact path="/ticket/:dsarRequestId/:appId/viewrequestjson" component={ViewJSON} />
            <Route exact path="/contacts" component={ContactsTab} />
            <Route exact path="/documents" render={(r) => <LinksPage header="Documents" />} />
            <Route exact path="/cps_health" render={(r) => <Health header="CPS Health" />} />
            <Route exact path="/apps" component={Automation} />
            <Route exact path="/attribute_keys" component={MasterAttributeKeys} />
            <Route exact path="/bulk_assign" component={BulkAssign} />
            <Route exact path="/retention" component={Retention} />
            <Route exact path="/users" component={Users} />
            <Route exact path="/litigation_hold" component={LitigationHold} />
            <Route exact path="/processing_requests" component={ProcessingRequests} />
            <Route exact path="/ctp_aggregator_status" component={CtpAggregator} />
            <Route exact path="/autoclose_settings" component={AutoCloseSettings} />
        </>
    )
}
export default OldUIRouter;