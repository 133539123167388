/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useContext  } from 'react';
import { AppContext } from '../../RoutesWithAuth';
import { Link  } from 'react-router-dom';
import axios from './AxiosConfig';
import PageFooter from './PageFooter';
import { UncontrolledTooltip } from 'reactstrap';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import { signOut } from './AuthHelper';


function Navbar(props) {
    const { activeIndex } = props
    const context = useContext(AppContext)
    const authToken = context.authToken.get
    const isAdmin = context.isAdmin.get
    const userName = context.userName.get
    const RTD = context.RTD.get


    useEffect(() => {
        const getRequestHealth = () => {
            axios.get("reports/request_health", {
                headers: {
                    Authorization: authToken
                }
            }).then(res => {
                context.RTC.set(res.data.rtc_failed)
                context.RTCRedirect.set(res.data.rtc_redirect)
                context.RTD.set(res.data.rtd_failed)
                context.RTDRedirect.set(res.data.rtd_redirect)
                context.RTK.set(res.data.rtk_failed)
                context.RTKRedirect.set(res.data.rtk_redirect)
                context.RTOO.set(res.data.rtoo_failed)
                context.RTOORedirect.set(res.data.rtoo_redirect)
                context.oldest.set(res.data.days)
                context.systems.set(res.data.systems)
                context.failures.set(res.data.ingestion_failures)
                context.closed_ticket_count.set(res.data.closed_ticket_count)
                context.duplicate_ticket_count.set(res.data.duplicate_ticket_count)
                context.pending_attachments_count.set(res.data.pending_attachments_count)
                context.tips_error_count.set(res.data.tips_error_count)
                context.ctp_agg_error_count.set(res.data.ctp_agg_error_count)
            })
        }

        if (authToken !== null && RTD === null) {
            getRequestHealth()
            setInterval(() => getRequestHealth(), 15 * 60 * 1000)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authToken])

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light shadow-sm mb-3">
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon" />
            </button>
            <Link to="/">
                <span className="navbar-brand">
                    <img src="/ct-cps-logo.png" width="150" height="40" alt=""></img>
                </span>
            </Link>

            <div className="collapse navbar-collapse" id="navbarToggler">
                <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                    <li className="nav-item">
                        <Link to="/" className="no-decor">
                            <span className={`nav-link ${activeIndex === 1 ? 'active' : ''}`}>Home</span>
                        </Link>
                    </li>
                    {/* <li className={`nav-item dropdown ${activeIndex === 2 ? 'active' : ''}`}>
                        <a href="#" className="nav-link dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                            Resources
                        </a>
                        <div className="dropdown-menu">
                            <Link to="/contacts" className="no-decor">
                                <span className="dropdown-item">Contacts</span>
                            </Link>
                            <Link to="/attribute_keys" className="no-decor">
                                <span className="dropdown-item">Data Dictionary</span>
                            </Link>
                            <Link to="/documents" className="no-decor">
                                <span className="dropdown-item">Documents</span>
                            </Link>
                        </div>
                    </li> */}
                    {/* <li className={`nav-item dropdown ${activeIndex === 3 ? 'active' : ''}`}>
                        <a href="#" className="nav-link dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                            Monitor
                        </a>
                        <div className="dropdown-menu">
                            <Link to="/cps_health" className="no-decor">
                                <span className="dropdown-item">CPS Health</span>
                            </Link>
                            <Link to="/ctp_aggregator_status" className="no-decor">
                                <span className="dropdown-item">CTP Aggregator</span>
                            </Link>
                            <Link to="/processing_requests" className="no-decor">
                                <span className="dropdown-item">Processing Requests</span>
                            </Link>
                        </div>
                    </li> */}

                    {/* {isAdmin ?
                        <li className={`nav-item dropdown ${activeIndex === 4 ? 'active' : ''}`}>
                            <a href="#" className="nav-link dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                Admin
                            </a>
                            <div className="dropdown-menu">
                                <Link to="/apps" className="no-decor">
                                    <span className="dropdown-item">Apps</span>
                                </Link>
                                <Link to="/autoclose_settings" className="no-decor">
                                    <span className="dropdown-item">Autoclose Settings</span>
                                </Link>
                                <Link to="/bulk_assign" className="no-decor">
                                    <span className="dropdown-item">Bulk Assign</span>
                                </Link>
                                <Link to="/ticket/create" className="no-decor">
                                    <span className="dropdown-item">Create DSAR</span>
                                </Link>
                                <Link to="/litigation_hold" className="no-decor">
                                    <span className="dropdown-item">Litigation Hold</span>
                                </Link>
                                <Link to="/retention" className="no-decor">
                                    <span className="dropdown-item">Retention</span>
                                </Link>
                                <Link to="/users" className="no-decor">
                                    <span className="dropdown-item">Users</span>
                                </Link>
                            </div>
                        </li>
                        : null
                    } */}
                    <li className={'nav-item dropdown'} >
                        <a href={'/'} rel="noopener noreferrer" className="btn btn-sm btn-success mt-1" target="_self" onClick={()=>{
                            sessionStorage.removeItem("version");
                            window.location.href = "/";
                        }} >
                            CPS 2.0
				        </a>
                    </li>
                    <li className="nav-item d-lg-none">
                        <a className="nav-link" href="#" onClick={()=>signOut(context)}>Sign Out</a>
                    </li>
                </ul>

                {/* <HealthDashboard small={false} /> */}

                <span className="d-none d-lg-block d-xl-block text-center">
                    {userName}
                    <br />
                    <button className="btn btn-sm py-0 btn-dark" onClick={()=>signOut(context)}>Sign Out</button>
                </span>
            </div>

            {/* <HealthDashboard small={true} /> */}
        </nav>
    );
}

// Reusable health dashboard component
function HealthDashboard(props) {
    const { small } = props

    const context = useContext(AppContext)
    const RTD = context.RTD.get
    const RTK = context.RTK.get
    const RTOO = context.RTOO.get
    const RTC = context.RTC.get
    let oldest = context.oldest.get
    const systems = context.systems.get
    const RTDRedirect = context.RTDRedirect.get
    const RTKRedirect = context.RTKRedirect.get
    const RTOORedirect = context.RTOORedirect.get
    const RTCRedirect = context.RTCRedirect.get

    const closed_ticket_count = context.closed_ticket_count.get
    const duplicate_ticket_count = context.duplicate_ticket_count.get
    const pending_attachments_count = context.pending_attachments_count.get
    const tips_error_count = context.failures.get    
    const ctp_agg_error_count = context.ctp_agg_error_count.get

    const [modalShow, setModalShow] = useState(false);

    const toggle = () => setModalShow(!modalShow);

    let hover_string = ""
    const alerts = []
    let word = ''

    if (closed_ticket_count !== 0)
    {
        alerts.push("Closed Request")
    }
    if (duplicate_ticket_count !== 0)
    {
        alerts.push("Duplicate Request")
    }
    if (pending_attachments_count !== 0)
    {
        alerts.push("Pending Attachment")
    }
    if (tips_error_count !== 0)
    {
        alerts.push("TIPS Ingestion")
    }    
    if (ctp_agg_error_count !== 0)
    {
        alerts.push("CTP Aggregator")
    }
    if (alerts.length > 0){
        hover_string = alerts+" alert(s) present. Please click for details."
    }

    let calendarColor, daysTooltip = ""
    if (oldest === null || oldest === undefined) { oldest = 0 }
    else if (oldest < 4) { calendarColor = "text-success" }
    else if (oldest > 6) { calendarColor = "text-danger"; daysTooltip = "7+" }
    else { calendarColor = "text-warning"; daysTooltip = "4 - 6" }

    let failureColor = "text-success"
    if (alerts.length > 0) {
        failureColor = "text-danger"
    }

    if (systems === null){
        calendarColor = "text-success"
    }

    if (systems !== null){
        if (systems.length === 1){
            word = 'is'
        }
        if (systems.length > 1){
            word = 'are'
        }
    }

    let parentClass = ""
    if (small) { parentClass = "status-dashboard d-flex d-lg-none flex-row text-center" }
    else { parentClass = "d-none d-lg-flex flex-row text-center" }

    return (
        <div className={parentClass}>
            <div className="px-2 mr-2">
                <div className="m-0" id="ingestion">
                    <img alt="" src={alerts.length > 0 ? "/alert_red.png" : "/alert_green.png"} width="25" height="23" onClick={() => setModalShow(true)} />
                    <div className="small">{alerts.length > 0 ? `(${alerts.length}) Alerts` : null}</div>
                </div>
                <Modal isOpen={modalShow} toggle={toggle} >
                <ModalHeader toggle={toggle}>Alerts</ModalHeader>
                <ModalBody>
                <div>
                    <table className="table table-bordered" id="alertModal" style={{ background: "white", border: "1.4px solid #000", marginBottom: "0px" }}>
                        <thead>
                            <tr style={{ background: "#6fb850", color: "#fff" }}>
                                <th>Status</th>
                                <th id="alertModalHead">Alert Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <Link to="/reports/Aging Report Closed" onClick={toggle}> 
                                        <img alt="" src={closed_ticket_count > 0 ? "/Closed_Red.png" : "/Closed_Green.png"} width="50" height="40"></img>
                                    </Link>
                                </td>
                                <td>
                                    <Link to="/reports/Aging Report Closed" onClick={toggle}>
                                        {closed_ticket_count > 0 ? `${closed_ticket_count} requests in closed status` : 'No closed requests'}
                                    </Link>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Link to="/reports/Duplicate Requests" onClick={toggle}>
                                        <img alt="" src={duplicate_ticket_count > 0 ? "/duplicate_Red.png" : "/duplicate_green.png"} width="50" height="40"></img>
                                    </Link>
                                </td>
                                <td>
                                    <Link to="/reports/Duplicate Requests" onClick={toggle}>
                                        {duplicate_ticket_count > 0 ? `${duplicate_ticket_count} duplicate requests in CPS` : 'No duplicate requests'}
                                    </Link>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Link to="/reports/Pending Attachments" onClick={toggle}>
                                        <img alt="" src={pending_attachments_count > 0 ? "/Att_Red.png" : "/Att_Green.png"} width="50" height="40"></img>
                                    </Link>
                                </td>
                                <td>
                                    <Link to="/reports/Pending Attachments" onClick={toggle}>
                                        {pending_attachments_count > 0 ? `${pending_attachments_count} requests with pending attachments` : 'No pending attachments'}
                                    </Link></td>
                            </tr>
                            <tr>
                                <td>
                                    <Link to="/processing_requests" onClick={toggle}>
                                        <img alt="" src={tips_error_count > 0 ? "/TIPS_Ingestion_Red.png" : "/TIPS_Ingestion_Green.png"} width="60" height="40"></img>
                                    </Link></td>
                                <td>
                                    <Link to="/processing_requests" onClick={toggle}>
                                        {tips_error_count > 0 ? `${tips_error_count} requests from TIPS are in error status` : 'Requests from TIPS are ingested successfully'}
                                    </Link></td>
                            </tr>
                            <tr>
                                <td>
                                    <Link to="/ctp_aggregator_status" onClick={toggle}>
                                        <img alt="" src={ctp_agg_error_count > 0 ? "/Aggregator_Red.png" : "/Aggregator_Green.png"} width="60" height="40"></img>
                                    </Link></td>
                                <td>
                                    <Link to="/ctp_aggregator_status" onClick={toggle}>
                                        {ctp_agg_error_count > 0 ? `${ctp_agg_error_count} tickets in CTP aggregator are in error status` : 'CTP aggregator is processing successfully'}
                                    </Link></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                </ModalBody>
                </Modal>
                <UncontrolledTooltip placement="bottom" target="ingestion">
                    {failureColor === "text-success" ?
                        "No additional alerts in CPS"
                        :
                        `${hover_string}`
                    }
                </UncontrolledTooltip>
            </div>
            <div className="px-2 mr-2">
                <Link to="/reports/Aging Report Open" className="no-decor dash-section" id="processing-days">
                    <div className="m-0">
                        <span className={"material-icons dash-icon " + calendarColor}>calendar_today</span>
                    </div>
                    <div className="small">{oldest !== null ? `${oldest}` : null} day(s)</div>
                </Link>
                <UncontrolledTooltip placement="bottom" target="processing-days">
                    {calendarColor === "text-success" ?
                        "Downstream systems are processing requests within 3 days"
                        :
                        `${Object.values(systems).join(', ')} ${word} taking ${daysTooltip} days to process requests`
                    }
                </UncontrolledTooltip>
            </div>
            <div className="px-2 mr-2">
                <Link to={RTCRedirect} className="no-decor dash-section" id="RTC">
                    <div className={`my-1  ${RTC > 0 ? "led-red" : "led-green"}`}></div>
                    <div className="small dash-text">RTC {RTC ? `(${RTC})` : null}</div>
                </Link>
                <UncontrolledTooltip placement="bottom" target="RTC">
                    {RTC > 0 ?
                        `CPS was unable to process/upload ${RTC} RTC requests`
                        :
                        "All RTC responses are being processed & uploaded to TIPS"
                    }
                </UncontrolledTooltip>
            </div>
            <div className="px-2 mr-2">
                <Link to={RTDRedirect} className="no-decor dash-section" id="RTD">
                    <div className={`my-1 ${RTD > 0 ? "led-red" : "led-green"}`}></div>
                    <div className="small dash-text">RTD {RTD ? `(${RTD})` : null}</div>
                </Link>
                <UncontrolledTooltip placement="bottom" target="RTD">
                    {RTD > 0 ?
                        `CPS was unable to process/upload ${RTD} RTD requests`
                        :
                        "All RTD responses are being processed & uploaded to TIPS"
                    }
                </UncontrolledTooltip>
            </div>
            <div className="px-2 mr-2">
                <Link to={RTKRedirect} className="no-decor dash-section" id="RTK">
                    <div className={`my-1 ${RTK > 0 ? "led-red" : "led-green"}`}></div>
                    <div className="small dash-text">RTK {RTK ? `(${RTK})` : null}</div>
                </Link>
                <UncontrolledTooltip placement="bottom" target="RTK">
                    {RTK > 0 ?
                        `CPS was unable to process/upload ${RTK} RTK requests`
                        :
                        "All RTK responses are being processed & uploaded to TIPS"
                    }
                </UncontrolledTooltip>
            </div>
            <div className="px-2 mr-2">
                <Link to={RTOORedirect} className="no-decor dash-section" id="RTO">
                    <div className={`my-1 ${RTOO > 0 ? "led-red" : "led-green"}`}></div>
                    <div className="small dash-text">RTO {RTOO ? `(${RTOO})` : null}</div>
                </Link>
                <UncontrolledTooltip placement="bottom" target="RTO">
                    {RTOO > 0 ?
                        `CPS was unable to process/upload ${RTOO} RTO requests`
                        :
                        "All RTO responses are being processed & uploaded to TIPS"
                    }
                </UncontrolledTooltip>
            </div>
        </div>
    )
}


export const withNavbar = (ComponentToWrap, activeIndex = 0) => {
    return (props) => (
        <div>
            <Navbar activeIndex={activeIndex} />

            <div id="body-container" className="d-flex flex-column">
                <div className="wrapper flex-grow-1">
                    <ComponentToWrap {...props} />
                </div>
                <PageFooter />
            </div>

        </div>
    )
}

export default Navbar;
