import React, { useContext } from 'react';
import { Redirect } from "react-router-dom";
import { AppContext } from './RoutesWithAuth';
import { getAuthToken } from './components/common/AuthHelper';


// This is the function that will check if a user is signed in or not, and then redirect to home screen
function WaitSignIn(props) {
    const { authState } = props;
    const context = useContext(AppContext)

    console.log('WaitSignIn ' + authState)

    if (authState === 'signedIn') {
        console.log('here ' + authState)
        
        const authToken = context.authToken.get

        if (authToken === null) {
            getAuthToken(context)
            return null
        }
        else {
            return <Redirect to={{pathname: "/"}} />
        }
    }

    // Should there be a loading symbol here?
    return null

}

export default WaitSignIn;
